/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ReactNode } from 'react'

type OverlayProps = {
  title: string
  content: ReactNode
}

const Overlay: React.FC<OverlayProps> = ({ title, content }) => {
  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
      `}
    >
      <div className='modal-background' />
      <div
        className='modal-content'
        css={css`
          width: 35%;
        `}
      >
        <div className='card'>
          <div className='card-header'>
            {title && <p className='card-header-title'>{title}</p>}
          </div>
          <div className='card-content'>
            <div className='is-flex is-justify-content-space-evenly'>
              {content}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Overlay
