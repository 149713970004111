import { useQuery } from 'react-query'

export function useInfo() {
  const query = useQuery<Info>('/info', { refetchOnWindowFocus: false })
  return {
    ...query,
    info: query.data,
  }
}

export interface Info extends Record<string, string> {
  env: string
  version: string
}
