import { useQuery } from 'react-query'

export function useVenueInfo() {
  const query = useQuery<VenueInfo>('/api/venue/info', {
    refetchOnWindowFocus: false,
  })
  return {
    ...query,
    info: query.data,
  }
}

export interface VenueInfo {
  venueId: string
  venueCode: string
  appEnv: string
}
