import { atom, useAtom, useSetAtom } from 'jotai'

const serverConnectionStatusAtom = atom<boolean>(false)
const useServerStatusAtom = () => useAtom(serverConnectionStatusAtom)
const useSetServerStatusAtom = () => useSetAtom(serverConnectionStatusAtom)

const amqConnectionStatusAtom = atom<boolean>(false)
const useAMQStatusAtom = () => useAtom(amqConnectionStatusAtom)
const useSetAMQStatusAtom = () => useSetAtom(amqConnectionStatusAtom)

export {
  amqConnectionStatusAtom,
  serverConnectionStatusAtom,
  useAMQStatusAtom,
  useServerStatusAtom,
  useSetAMQStatusAtom,
  useSetServerStatusAtom,
}
