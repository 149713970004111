import { useQuery } from 'react-query'

export function useSession() {
  const query = useQuery<Session>('/session', { refetchOnWindowFocus: false })
  return {
    ...query,
    session: query.data,
  }
}

export interface Session extends Record<string, any> {
  email: string
  groups: string[]
}
