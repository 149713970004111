/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Connection from 'src/elements/Connection'
import {
  useAMQStatusAtom,
  useServerStatusAtom,
} from 'src/hooks/atoms/useConnectionStatusAtoms'
import packageJson from '../../package.json'

const Footer = () => {
  const [serverConnection] = useServerStatusAtom()
  const [amqConnection] = useAMQStatusAtom()
  const connectionProps = { serverConnection, amqConnection }
  return (
    <div
      css={css`
        display: flex;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 40px;
        margin-top: 15px;
        background-color: white;
        box-shadow: 1em 0em 0.5em -0.125em rgba(10, 10, 10, 0.1),
          0 0px 0 1px rgba(10, 10, 10, 0.02);
        padding: 6px 10px 6px 6px;
        z-index: 1000;
        text-align: right;
        justify-content: space-between;
      `}
    >
      <div>Release: v{packageJson.version}</div>
      <Connection {...connectionProps} />
    </div>
  )
}

export default Footer
