/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

enum AlertType {
  WARNING,
  DANGER,
  SUCCESS,
}

type AlertProps = {
  type: AlertType
  text: { primary: string; secondary: string }
}

const Alert: React.FC<AlertProps> = ({ type, text }) => {
  const icon = solid('exclamation-triangle')
  const color = type == AlertType.DANGER ? '#b12842' : '#ffae42'
  const messages = text?.secondary.split('\n')
  return (
    <div className='is-flex '>
      <FontAwesomeIcon
        style={{ color: color, marginLeft: '2rem' }}
        icon={icon}
        size={'4x'}
      />
      <div
        className={
          'is-flex is-justify-content-center is-align-items-center is-justify-content-space-evenly'
        }
        css={css`
          width: 100%;
        `}
      >
        <div>
          <div
            css={css`
              color: ${color};
              font-size: 1.5rem;
            `}
          >
            {text.primary}
          </div>
          {messages.map((line, index) => (
            <div
              css={css`
                font-size: 1.2rem;
              `}
              key={index}
            >
              {line}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export { Alert, AlertType }
