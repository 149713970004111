import axios, { AxiosRequestConfig } from 'axios'
import QueryString from 'qs'
import { FC } from 'react'
import {
  QueryClient,
  QueryClientProvider,
  QueryFunction,
  QueryKey,
} from 'react-query'

const client = axios.create({
  paramsSerializer: (params) => {
    return QueryString.stringify(params, { arrayFormat: 'comma' })
  },
})

export const defaultQueryFunction: QueryFunction<
  any,
  string | [string, AxiosRequestConfig] | QueryKey
> = ({ queryKey }) => {
  if (typeof queryKey === 'string') {
    return client.get(queryKey).then(({ data }) => data)
  }
  if (Array.isArray(queryKey)) {
    const [path, params] = queryKey
    return client.get(path, { params }).then(({ data }) => data)
  }
  throw new Error(
    'Unexpected queryKey format - must supply string or [path, queryParams]'
  )
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFunction,
      staleTime: 0,
      cacheTime: 0,
    },
  },
})

export const ReactQueryProvider: FC = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}
