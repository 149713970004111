import { datadogRum } from '@datadog/browser-rum'
import { lazy, PropsWithChildren, Suspense } from 'react'
import { LoaderIcon, Toaster } from 'react-hot-toast'
import { ReactQueryDevtools } from 'react-query/devtools'
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'
import { ReactQueryProvider } from './components/ReactQueryProvider'
import { useInfo } from './hooks/queries/useInfo'
import { useVenueInfo } from './hooks/queries/useVenueInfo'
import { useSession } from './hooks/useSession'
import './styles/index.scss'
import BenderAlerts from './elements/BenderAlerts'
import Footer from './components/Footer'

const Navbar = lazy(() =>
  import('src/components/Navbar').then((module) => ({ default: module.Navbar }))
)
const GamePage = lazy(() =>
  import('src/pages/GamePage').then((module) => ({ default: module.GamePage }))
)
const LoginPage = lazy(() =>
  import('src/pages/LoginPage').then((module) => ({
    default: module.LoginPage,
  }))
)
const LandingPage = lazy(() =>
  import('src/pages/LandingPage').then((module) => ({
    default: module.LandingPage,
  }))
)

function App() {
  return (
    <ReactQueryProvider>
      <BrowserRouter>
        <AuthRequired>
          <Suspense fallback={<LoaderIcon />}>
            <BenderAlerts>
              <Navbar />
              <Routes>
                <Route path='games/:gamePk' element={<GamePage />} />
                <Route path='events/:gamePk' element={<GamePage />} />
                <Route path='/login' element={<LoginPage />} />
                <Route path='/' element={<LandingPage />} />
              </Routes>
            </BenderAlerts>
          </Suspense>
        </AuthRequired>
      </BrowserRouter>
      <Toaster />
      <ReactQueryDevtools initialIsOpen={false} />
      <Footer />
    </ReactQueryProvider>
  )
}

export default App

function AuthRequired({ children }: PropsWithChildren<any>) {
  const { pathname } = useLocation()
  const { isLoading, session } = useSession()
  const { info } = useInfo()
  const venueInfo = useVenueInfo()

  if (isLoading) {
    return null
  }

  if (!session && pathname !== '/login') {
    return <Navigate to='/login' />
  }

  datadogRum.init({
    applicationId: 'c3c79dbf-2359-4638-ab79-f26c23158fcf',
    clientToken: 'pub5cc658e944c641157178bd7ec0cf1bcb',
    site: 'us5.datadoghq.com',
    service: 'bdp---bender',
    env: venueInfo?.data?.venueCode,
    version: info?.version,
    sampleRate: 100,
    replaySampleRate: 100,
    trackInteractions: true,
  })
  if (session) {
    const datadogUser = {
      email: session.email,
      name: session.name,
      id: session.name,
    }
    datadogRum.setUser(datadogUser)
    datadogRum.startSessionReplayRecording()
  } else {
    try {
      datadogRum.stopSessionReplayRecording()
    } catch (err) {
      // no session recording happening
    }
  }

  return <>{children}</>
}
