import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import { atom, useAtom, useSetAtom } from 'jotai'
import { ReactElement } from 'react'
import { AlertType } from 'src/elements/Alert'

type Alert = {
  title?: string
  type: AlertType
  text: { primary: string; secondary: string }
  component?: ReactElement | EmotionJSX.Element | (() => EmotionJSX.Element)
}

const alertAtom = atom<Alert | undefined>(undefined)
const useAlertAtom = () => useAtom(alertAtom)
const useSetAlertAtom = () => useSetAtom(alertAtom)

export { alertAtom, useAlertAtom, useSetAlertAtom }
