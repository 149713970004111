/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useEffect, useState } from 'react'

type ConnectionProps = {
  serverConnection: boolean
  amqConnection: boolean
}

const Connection: React.FC<ConnectionProps> = ({
  serverConnection,
  amqConnection,
}) => {
  const [serverColor, setServerColor] = useState<string>('red')
  const [amqColor, setAmqColor] = useState<string>('red')

  useEffect(() => {
    setServerColor(serverConnection ? 'green' : 'red')
    setAmqColor(amqConnection ? 'green' : 'red')
  }, [serverConnection, amqConnection])

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <p>Server status</p>
      <div
        css={css`
          margin: 2px 10px 0px 5px;
          background-color: ${serverColor};
          height: 20px;
          width: 20px;
          transition: height 100ms ease 0s, width 100ms ease 0s;
          box-shadow: ${serverColor} 0px 0px 0px;
          border-radius: 50%;
        `}
      />
      <p>AMQ status</p>
      <div
        css={css`
          margin: 2px 2px 0px 5px;
          background-color: ${amqColor};
          height: 20px;
          width: 20px;
          transition: height 100ms ease 0s, width 100ms ease 0s;
          box-shadow: ${amqColor} 0px 0px 0px;
          border-radius: 50%;
        `}
      />
    </div>
  )
}

export default Connection
