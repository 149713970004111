/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useAlertAtom } from 'src/hooks/atoms/useAlertAtom'
import Overlay from './Overlay'
import { Alert } from './Alert'

const BenderAlerts: React.FC = ({ children }) => {
  const [alert] = useAlertAtom()

  return (
    <>
      {alert && (
        <Overlay
          title={alert?.title ?? ''}
          content={
            <div
              css={css`
                width: 100%;
              `}
            >
              <Alert type={alert.type} text={alert.text} />
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                `}
              >
                {alert?.component && (
                  <>
                    {typeof alert.component === 'function' ? (
                      <alert.component />
                    ) : (
                      alert.component
                    )}
                  </>
                )}
              </div>
            </div>
          }
        />
      )}
      {children}
    </>
  )
}

export default BenderAlerts
